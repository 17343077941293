import Link from "next/link";
import React, { useState, useEffect, useRef } from "react";
import { useShoppingCart } from "../../context/shopping-cart-context.js";
import useDisableBodyScroll from "../../hooks/useDisableBodyScroll.js";
import useMediaQuery from "../../hooks/useMediaQuery.js";
import useOnClickOutside from "../../hooks/useOnClickOutside.js";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMagnifyingGlass, faShoppingBag } from "@fortawesome/pro-regular-svg-icons";

import styles from "./Nav.module.scss";

import Location from "../../components/location/Location.js";
import MobileMenu from "./MobileMenu.js";
import Searchbar from "./Searchbar.js";
import SecondaryNav from "./SecondaryNav.js";
import Button from "../../components/Button/button.js";
import Cart from "../../components/bag";
import { useRouter } from "next/router";
import Image from "next/image.js";

export default function Nav({ ageGate }) {
  const [backdrop, setBackdrop] = useState(false);
  const isBreakPointTablet = useMediaQuery(768);

  return (
    <>
      <nav>
        <div className={styles.wrapper}>
          <nav className={styles.navbar}>
            <div className="border-bottom relative">
              <div className={`${styles.nav} container container__slim`}>
                <ModalMenu
                  title="menu"
                  icon={faBars}
                  setBackdrop={setBackdrop}
                  className={`${styles.modalMobileMenu} ${backdrop ? styles.modalMobileMenu__active : undefined}`}
                >
                  <MobileMenu />
                </ModalMenu>

                <Link href="/" aria-label="home" className={`${styles.logo} pr-100`}>
                  <Image
                    src="/purple-lotus-logo-black-text.svg"
                    alt="purple lotus website logo"
                    width={160} 
                    height={45}
                    priority  
                  />
                </Link>

                {!isBreakPointTablet && (
                  <>
                    <Searchbar />
                    <Location ageGate={ageGate} />
                  </>
                )}

                {isBreakPointTablet && (
                  <ModalMenu
                    title="search"
                    icon={faMagnifyingGlass}
                    setBackdrop={setBackdrop}
                    className={styles.modalSearchMenu}
                  >
                    <Searchbar />
                  </ModalMenu>
                )}

                <ModalMenu
                  title="shopping-bag"
                  icon={faShoppingBag}
                  setBackdrop={setBackdrop}
                  className={styles.janeCart}
                  id="cartModal"
                >
                  <Cart />
                </ModalMenu>

              </div>
            </div>
          </nav>
        </div>
      </nav>
      {isBreakPointTablet && <Location ageGate={ageGate} />}
      <SecondaryNav />
    </>
  );
}

function ModalMenu(props) {
  const router = useRouter();
  const { cartQuantity, cartModal, setCartModal } = useShoppingCart();

  // Determine whether to use the context state or component state
  const isShoppingBag = props.title === "shopping-bag";
  const [localOpen, setLocalOpen] = useState(false);

  // Use local state for non-shopping bag, and context state for shopping bag
  const open = isShoppingBag ? cartModal : localOpen;
  const setOpen = isShoppingBag ? setCartModal : setLocalOpen;

  useDisableBodyScroll(open);

  const handleClick = (e) => {
    // e.preventDefault(); // Prevent the default link behavior
    setOpen(!open);
    toast.dismiss();
  };

  useEffect(() => {
    props.setBackdrop(open);
  }, [open]);

  useEffect(() => {
    if (router.pathname === '/checkout' && isShoppingBag) {
      setOpen(true);
    }
  }, [router.pathname, isShoppingBag, setOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  // passing handleClick to all modal children
  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        open: open,
        handleClick: handleClick,
        handleClose: handleClose,
      });
    });
  };

  const modalMenuRef = useRef(null);
  useOnClickOutside(modalMenuRef, () => handleClose(), "#openModal");

  return (
    <div
      className={`${styles.modalMenu} ${props.className}`}
      ref={modalMenuRef}
    >

      <Button
        type="button"
        className="icon"
        onClick={handleClick}
        ariaLabel={props.title}
      // href={`/?shopping-bag`}
      // as={`/shopping-bag`}
      >
        <FontAwesomeIcon icon={props.icon} />
        {props.title == "shopping-bag" &&
          // displays bag quantity
          cartQuantity > 0 && (
            <span className={styles.bagQuantity}>{cartQuantity}</span>
          )}
      </Button>

      <div
        className={`${styles.modalMenu__modal} ${open ? styles.modalMenu__modal__active : undefined}`}
        tabIndex={-1}
        id={props.id}
      >
        <div className="relative">
          <div className={styles.modalMenu__content}>{renderChildren()}</div>
        </div>
      </div>
      <div
        className={`${styles.backdrop} ${open && styles.backdrop_active}`}
        onClick={() => setOpen(false)}
      ></div>
    </div>
  );
}
