const navItems = {
  "navItems": [
    {
      "title": "home",
      "link": "/"
    },
    {
      "title": "shop",
      "link": "#",
      "links": [
        {
          title: "all products",
          link: "/shop"
        },
        {
          title: "flower",
          link: "/shop/flower"
        },
        {
          title: "pre-roll",
          link: "/shop/pre-roll"
        },
        {
          title: "extract",
          link: "/shop/extract"
        },
        {
          title: "vape",
          link: "/shop/vape"
        },
        {
          title: "edible",
          link: "/shop/edible"
        },
        // {
        //   title: "drinks",
        //   link: "/shop/edible:drinks"
        // },
        {
          title: "tincture",
          link: "/shop/tincture"
        },
        {
          title: "topical",
          link: "/shop/topical"
        },
        {
          title: "gear",
          link: "/shop/gear"
        },
        {
          title: "merch",
          link: "/shop/merch"
        },
      ]
    },
    {
      "title": "deals",
      "link": "/deals"
    },
    // {
    //   "title": "events",
    //   "link": "/events"
    // },
    {
      "title": "rewards",
      "link": "/rewards"
    },
    {
      "title": "brands",
      "link": "/brands"
    },
    {
      "title": "pickup",
      "link": "/pickup"
    },
    {
      "title": "delivery",
      "link": "/weed-delivery"
    },
     {
      "title": "about",
      "link": "#",
      "links": [
        {
          "title": "about us",
          "link": "/about"
        },
        {
          "title": "our brands",
          "link": "/our-brands"
        },
        {
          "title": "our community",
          "link": "/our-community"
        },
        {
          "title": "blog",
          "link": "/blog"
        },
        {
          "title": "reviews",
          "link": "/reviews"
        },
        {
          "title": "careers",
          "link": "/careers"
        },
      ]
    },
     {
      "title": "help",
      "link": "#",
      "links": [
        {
          "title": "FAQ",
          "link": "/faq"
        },
        {
          "title": "contact",
          "link": "/contact"
        },
        {
          "title": "How to order",
          "link": "/how-to-order"
        },
        {
          "title": "Pay methods",
          "link": "/payment-options"
        },
        {
          "title": "returns",
          "link": "/return-policy"
        },
        {
          "title": "site map",
          "link": "/site-map"
        }
      ]
    },
  ],


  // footer items
  "footerItems": [
    {
      "title": "shop",
      "links": [
        {
          "title": "shop all",
          "link": "/shop"
        },
        {
          "title": "pickup",
          "link": "/pickup"
        },
        {
          "title": "delivery",
          "link": "/weed-delivery"
        },
        {
          "title": "deals",
          "link": "/deals"
        },
        {
          "title": "rewards",
          "link": "/rewards"
        },
        {
          "title": "brands",
          "link": "/brands"
        },
      ]
    },
     {
      "title": "About",
      "links": [
        {
          "title": "About us",
          "link": "/about"
        },
        {
          "title": "our brands",
          "link": "/our-brands"
        },
        {
          "title": "our community",
          "link": "/our-community"
        },
        {
          "title": "blog",
          "link": "/blog"
        },
        {
          "title": "careers",
          "link": "/careers"
        },
        {
          "title": "reviews",
          "link": "/reviews"
        },
      ]
    },
     {
      "title": "help",
      "links": [
        {
          "title": "FAQ",
          "link": "/faq"
        },
        {
          "title": "contact",
          "link": "/contact"
        },
        {
          "title": "How to order",
          "link": "/how-to-order"
        },
        {
          "title": "returns",
          "link": "/return-policy"
        },
        {
          "title": "unsubscribe",
          "link": "/unsubscribe"
        },
        {
          "title": "site map",
          "link": "/site-map"
        }
      ],
      
    },
    //  {
    //   "title": "account",
    //   "links": [
    //     {
    //       "title": "login",
    //       "link": "#"
    //     },
    //     {
    //       "title": "bag",
    //       "link": "#"
    //     },
    //     {
    //       "title": "your orders",
    //       "link": "#"
    //     },
    //     {
    //       "title": "your account",
    //       "link": "#"
    //     }
    //   ]
    // }
  ],
  "legalItems": [
    {
      "title": "terms of use",
      "link": "/terms-of-use"
    },
    {
      "title": "privacy policy",
      "link": "/privacy-policy"
    },
  ]
};

export {navItems};