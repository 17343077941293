import '../styles/reset.scss';
import '../styles/globals.scss';
import '../styles/toastify.scss';
import '../styles/swiper.scss';
import '../styles/ais.scss';
import "../styles/nprogress.scss";

import { Amplify } from "aws-amplify";
import { Authenticator } from '@aws-amplify/ui-react';
// import awsConfig from '../lib/awsConfig';

import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { StoreContextProvider } from '../context/store-context';
import { ShoppingCartProvider } from '../context/shopping-cart-context';
import AgeGate from '../templates/age-gate/age-gate-location';
import { useState, useEffect } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import localFont from 'next/font/local'
import Head from 'next/head'
import Cookies from 'js-cookie';
import { GoogleTagManager } from '@next/third-parties/google'
import { DefaultSeo } from 'next-seo';
import useScrollRestoration from '../hooks/useScrollRestoration';
import { SpeedInsights } from "@vercel/speed-insights/next"
import { Inter } from 'next/font/google';

import dynamic from 'next/dynamic';

import Nav from '../templates/nav';

const Footer = dynamic(() => import('../templates/footer'));

// console.log(awsConfig.cognito);

Amplify.configure({
  Auth: {
    Cognito: {
      region: 'us-west-1',
      userPoolClientId: '7r12he3ll17kedc0t8pq7hc5al',
      userPoolId: 'us-west-1_tv50QHqZj',
      loginWith: { 
        oauth: {
          domain: 'https://janers.auth.us-west-1.amazoncognito.com',
          scopes: ['openid email phone profile aws.cognito.signin.user.admin '],
          redirectSignIn: ['/dashboard'],
          redirectSignOut: ['http://localhost:3000/'],
          responseType: 'code',
        },
      }
    }
  },
  ssr: true,
});

const hideFromRoutes = [ "/test", "/old", "/downtown-grand-opening", "/cart"]

// Function to determine if the current route should hide the Nav and Footer
const shouldHideNavAndFooter = (pathname) => {
  // Check if the route matches any path in hideFromRoutes or starts with '/checkout/'
  return hideFromRoutes.includes(pathname) || pathname.startsWith("/receipt/");
};


// const myFont = localFont({ src: '../public/fonts/inter.ttf' })
const myFont = Inter({ subsets: ['latin'] });

export default function App({ Component, pageProps, router }) {

  const hide = shouldHideNavAndFooter(router.pathname); //hide content wrap on pages w/o nav
  const [ageGate, setAgeGate] = useState();

  useScrollRestoration(router);

  // testing promo. TODO Set the jame promo code cookie
  useEffect(() => {
    const promo = router.query.promo;
    const currentCookie = Cookies.get('JANE_PROMO') || '';

    // If the promo query parameter exists and is different from the current cookie, set the cookie
    if (promo && promo !== currentCookie) {
      Cookies.set('JANE_PROMO', promo, { expires: 1 });
    }

  }, [router.query.promo]);


  // Function to handle external links
  useEffect(() => {
    // Function to handle external links
    const handleExternalLinks = () => {
      
      const links = document.querySelectorAll('a');
      links.forEach(link => {
        const href = link.getAttribute('href');
        
        // Check if the link is external
        if (href && href.startsWith('http') && !href.includes('plpcsanjose.com')) {
          // Add target="_blank" and rel="noopener noreferrer"
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer');
        }
      });
    };

    // Call the function when the component mounts
    handleExternalLinks();

    // Listen for route changes and re-run the function
    const handleRouteChange = () => {
      handleExternalLinks();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // Cleanup function
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />

      </Head>
      {/* <SpeedInsights/> */}
      <GoogleTagManager gtmId="GTM-KQQS78R" />
      <Authenticator.Provider>
        <StoreContextProvider>
          <ShoppingCartProvider>

            <a href="#content" className="skip-to-main-content-link">Skip to main content</a>
            <div id="content"
              className={`${myFont.className} ${hide ? null : 'content-wrap'}`}>

              <AgeGate router={router} ageGate={ageGate} setAgeGate={setAgeGate} />
              {/* {!ageGate && <NewsletterPopup />} */}
              {hide ? null : <Nav ageGate={ageGate} />}

              {/* <ProgressBar /> */}

              <Component {...pageProps} />
              <ToastContainer />
            </div>
            <DefaultSeo
              openGraph={{
                type: 'website',
                locale: 'en_US',
                url: 'https://www.plpcsanjose.com/',
                siteName: 'Purple Lotus',
              }}
              twitter={{
                handle: '@purplelotusca',
                site: '@purplelotusca',
                cardType: 'summary_large_image',
              }}
            />
          </ShoppingCartProvider>
        </StoreContextProvider>
      </Authenticator.Provider>

      {hide ? null : <Footer fontClass={myFont.className} />}
    </>
  )
}
